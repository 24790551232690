import { getSubdomainURL } from "@src/utils/getSubdomainURL";

export type EntityType = "teacher" | "parent" | "student";
export function getWelcomeBackUrl(entityType: EntityType, isChildAsParent?: boolean) {
  const subdomain = getSubdomainURL(entityType);

  // If the user is a parent and isChildAsParent is true, we want to redirect them to the dojoislands/kids page
  // This is because home.classdojo.com won't work for those sessions.
  if (isChildAsParent) {
    return `${subdomain}/#/dojoislands/kids`;
  }
  return subdomain;
}
