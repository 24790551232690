import React from "react";
import { NessieThemeProvider } from "@classdojo/web";
import { DownCarrotIcon } from "@classdojo/web";

export const DownCaretIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <DownCarrotIcon {...props} />
    </NessieThemeProvider>
  );
};
