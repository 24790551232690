import styled from "@emotion/styled";

export const LabelText = styled.div`
  font-family: "HafferXH", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
`;
