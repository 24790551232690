import styled from "@emotion/styled";
import React, { useCallback, useEffect, useMemo } from "react";
import cookies from "cookies-js";
import { cookiesWork } from "@src/utils/cookies";
import { Link } from "gatsby";
import { mediaQueriesMax } from "@src/styles/theme";
import { logEvent } from "@src/utils/logClient";

type HelloBarStyledProps = React.ComponentProps<typeof HelloBarStyled>;

interface HelloBarProps extends HelloBarStyledProps {
  // href: string;
  text: string;
  cookieName?: string;
  trackingEventName?: string;
}

const HelloBarStyled = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(0, 9, 91, 0.1);
  background: #f1f3f8;
  padding: 10px 15px;
  color: #000;
  text-align: center;
  font-family: "HafferXH";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  position: relative;
  &:hover {
    text-decoration: underline;
    color: #7d40ff;
  }
  ${mediaQueriesMax[1]} {
    padding-inline: 15%;
    line-height: 135%;
  }
`;

const createCookieState = (cookieName: string) => ({
  isSet: (): boolean => (cookiesWork() ? cookies.get(cookieName) === "true" : true),
  set: () => cookiesWork() && cookies.set(cookieName, "true", { expires: 365 }),
});

const HelloBar = (props: HelloBarProps) => {
  const [show, setShow] = React.useState(false);
  const { text, to, cookieName, trackingEventName, ...rest } = props;

  const cookieState = useMemo(() => createCookieState(cookieName), [cookieName]);

  useEffect(() => {
    if (!cookieState.isSet()) setShow(true);
  }, [cookieState]);

  const handleClick = useCallback(() => {
    cookieState.set();
    setShow(false);
    logEvent({ eventName: trackingEventName, eventValue: window.location.href });
  }, [cookieState, trackingEventName]);

  if (!show) return null;

  return (
    <HelloBarStyled onClick={handleClick} to={to} {...rest}>
      {text}
    </HelloBarStyled>
  );
};

export default HelloBar;
