import { setTranslateFunction, TranslatableProps } from "@classdojo/web";
import { TranslationContextType } from "./TranslationContext";

// Single implementation that covers all overloads.
function buildClassdojoWebTranslate(context: TranslationContextType) {
  // Overload declarations for the translate function.
  function classdoojoWebTranslate(str: TranslatableProps["str"] | TranslatableProps): string;
  function classdoojoWebTranslate(str: TranslatableProps["str"], subs: TranslatableProps["subs"]): string;
  function classdoojoWebTranslate(str: TranslatableProps["str"], fallback: TranslatableProps["fallback"]): string;

  function classdoojoWebTranslate(strOrProps: any, secondArg?: any): string {
    // Case 1: If the first argument is an object, extract its properties.
    if (typeof strOrProps === "object") {
      const { str, subs, fallback } = strOrProps as TranslatableProps;

      return context.translateWithFallback({ str, subs, fallback }).toString();
    }

    const str = strOrProps as string;

    // Case 2: If the second argument is provided and is an object,
    // assume it represents substitution values.
    if (secondArg) {
      if (typeof secondArg === "object") {
        // is sub
        return context.translate(str, secondArg).toString();
      }

      if (typeof secondArg === "string") {
        // is fallback
        const fallback = secondArg;

        return context.translateWithFallback({ str, fallback }).toString();
      }
    }
    // Case 3: Otherwise, treat the second argument as a fallback string.
    return context.translate(str).toString();
  }

  return classdoojoWebTranslate;
}

export function setTranslationsFunctions(context: TranslationContextType) {
  setTranslateFunction(buildClassdojoWebTranslate(context));
}
