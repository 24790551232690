import React from "react";
import { Heading as NessieHeading, NessieThemeProvider } from "@classdojo/web";

export const Heading = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieHeading {...props} />
    </NessieThemeProvider>
  );
};
