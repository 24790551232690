import { isDev } from "@src/utils/env";

export type EntityType = "teacher" | "parent" | "student";
export function getSubdomainURL(entityType: EntityType) {
  let subdomain = "";
  let devPort = "";
  switch (entityType) {
    case "teacher":
      subdomain = "teach";
      devPort = "8084";
      break;
    case "parent":
      subdomain = "home";
      devPort = "8085";
      break;
    case "student":
      subdomain = "student";
      devPort = "8084";
      break;
  }
  if (!subdomain) throw new Error(`${entityType} has unknown subdomain`);
  return "https://" + subdomain + (isDev() ? `.classdojo.test:${devPort}` : ".classdojo.com");
}
