import React, { useContext, useState } from "react";
import CommonModal, { CommonModalProps } from "./CommonModal";
import LoginForm from "./LoginForm";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import isMobile from "@src/utils/isMobile";

const SchoolLeaderLoginModal = (props: CommonModalProps & { noRedirect?: boolean }) => {
  const t = useContext(TranslationContext);
  const mobile = isMobile();

  const [headerDisabled, setHeaderDisabled] = useState(false);
  return (
    <CommonModal
      fullPage={mobile.phone}
      headerText={headerDisabled ? undefined : t.translate("components.login.log_in_to_classdojo")}
      {...props}
    >
      <LoginForm
        userType="leader"
        closeModal={props.closeModal}
        noRedirect={props.noRedirect}
        showCodeLogin
        disableHeader={setHeaderDisabled}
      />
    </CommonModal>
  );
};

export default SchoolLeaderLoginModal;
