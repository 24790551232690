import React from "react";
import { css, Global } from "@emotion/react";
import { ThemeProvider } from "@emotion/react";
import Helmet from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import normalize from "../styles/normalize";
import theme from "../styles/theme";
import { TranslationProvider } from "@src/components/translation/TranslationContext";
import { AppDataContextProvider } from "@src/components/AppDataContext";
import OneTrustScript from "@src/components/partials/shared/OneTrustScript";
import styled from "@emotion/styled";
import "modern-normalize/modern-normalize.css";
import { generatingFor } from "@src/utils/routes";
import { graphql, useStaticQuery } from "gatsby";
import Container from "@src/components/Container";
import { Flex } from "@src/components/Boxes";
import StyledLayoutMain from "@src/layouts/common/StyledLayoutMain";

Modal.setAppElement("#___gatsby");

const SimpleHeader = styled("nav")`
  background-color: #575b7d;
  height: 50px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-width: 0;
  z-index: 1000;
`;

type SimpleLayoutProps = {
  children: any;
  pageContext: any;
};

const SimpleLayout: React.FC<SimpleLayoutProps> = ({ children, pageContext }) => {
  generatingFor.locale = pageContext.localeStrings.currentLocale;
  const data = useStaticQuery(graphql`
    {
      logo: file(name: { eq: "classdojo-logo-white@2x" }) {
        childImageSharp {
          gatsbyImageData(width: 132, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { logo } = data;
  return (
    <>
      <Helmet htmlAttributes={{ lang: pageContext.localeStrings.currentLocale }}>
        <link rel="stylesheet" href="https://static.classdojo.com/fonts/DojoText/DojoText.css" />
      </Helmet>
      <OneTrustScript />
      <Global
        styles={css`
          ${normalize};
          #___gatsby,
          #gatsby-focus-wrapper,
          main,
          html,
          body {
            height: 100%;
            background-color: #3e425d;
          }
        `}
      />
      <TranslationProvider localeStrings={pageContext.localeStrings}>
        <ThemeProvider theme={theme}>
          <AppDataContextProvider>
            <StyledLayoutMain id="main-page-container">
              <SimpleHeader>
                <Container height="100%">
                  <Flex height="100%" alignItems="center">
                    <GatsbyImage image={logo.childImageSharp.gatsbyImageData} alt="" />
                  </Flex>
                </Container>
              </SimpleHeader>
              {children}
            </StyledLayoutMain>
          </AppDataContextProvider>
        </ThemeProvider>
      </TranslationProvider>
    </>
  );
};

export default SimpleLayout;
