import React, { ComponentProps } from "react";
import { TextField as NessieTextField, NessieThemeProvider } from "@classdojo/web";

export const TextField = (props: ComponentProps<typeof NessieTextField>) => {
  return (
    <NessieThemeProvider>
      <NessieTextField {...props} />
    </NessieThemeProvider>
  );
};
