import React, { FC, useState, FocusEvent } from "react";
import styled from "@emotion/styled";
import Input, { InputProps } from "@src/components/forms/Input";
import { theme } from "../nessie-web";

interface InputLabelProps extends React.InputHTMLAttributes<HTMLInputElement>, InputProps {
  label: string;
}

const InputContainer = styled.div`
  position: relative;
  input {
    margin-block: 6px;
  }
  input:focus + label {
    color: ${theme.colors.contentAccent};
  }
`;

interface LabelProps {
  float: boolean;
}

const StyledLabel = styled.label<LabelProps>`
  position: absolute;
  left: 0.5rem;
  top: 15px;
  color: ${theme.colors.contentSecondary};
  padding: 0 0.25rem;
  transition: all 0.2s ease;
  pointer-events: none;
  ${({ float }) =>
    float &&
    `
    top: 5px;
    font-size: 0.75rem;
  `}
`;

const InputLabel: FC<Omit<InputLabelProps, "placeholder">> = ({
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) onFocus(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(event);
  };

  const hasValue = typeof value === "string" ? value.length > 0 : !!value;
  const float = isFocused || hasValue;

  return (
    <InputContainer>
      <Input
        aria-label={label}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inset={true}
        {...props}
      />
      <StyledLabel float={float}>{label}</StyledLabel>
    </InputContainer>
  );
};

export default InputLabel;
