import React from "react";
import { DetailHeading as NessieDetailHeading, NessieThemeProvider } from "@classdojo/web";

export const DetailHeading = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieDetailHeading {...props} />
    </NessieThemeProvider>
  );
};
