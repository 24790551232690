import React from "react";
import { DetailText as NessieDetailText, NessieThemeProvider } from "@classdojo/web";

export const DetailText = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieDetailText {...props} />
    </NessieThemeProvider>
  );
};
