import React, { forwardRef } from "react";

interface HtmlVideoProps {
  src: string;
  width: number | string;
  height?: number;
  autoPlay?: boolean;
  poster?: string;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  playsInline?: boolean;
}

const HtmlVideo = forwardRef<HTMLVideoElement, HtmlVideoProps>((props: HtmlVideoProps, ref) => {
  const { src, ...rest } = props;

  if (rest.autoPlay && rest.muted == undefined) rest.muted = true;

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video ref={ref} {...rest}>
      <source src={props.src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
});

HtmlVideo.displayName = "HtmlVideo";

export class HTMLVideoWrapper {
  constructor(private _video: HTMLVideoElement) {}

  get video() {
    return this._video;
  }

  play() {
    this._video.play();
  }

  stop() {
    this._video.pause();
    this._video.currentTime = 0;
  }

  mute(value: boolean) {
    this._video.muted = value;
  }
}

export default HtmlVideo;
