import { InputHTMLAttributes } from "react";
import styled from "@emotion/styled";
import { space, SpaceProps } from "styled-system";
import { css } from "@emotion/react";
import { NewStylesProps, NewInputStyles } from "./NewStylesConstants";
import { theme } from "../nessie-web";

interface VariantProps {
  success?: boolean;
  error?: boolean;
  rounded?: boolean;
  inset?: boolean;
}

interface StyledInputProps extends InputHTMLAttributes<HTMLInputElement>, SpaceProps, VariantProps, NewStylesProps {}

const variants = ({ success, error, rounded }: VariantProps) => {
  const all = [];
  if (success) {
    all.push(css`
      border-color: ${theme.colors.contentSuccess};
      &:focus {
        border-color: ${theme.colors.contentAccent};
      }
    `);
  }
  if (error) {
    all.push(css`
      border-color: ${theme.colors.contentDanger};
      &:focus {
        border-color: ${theme.colors.contentAccent};
      }
    `);
  }
  if (rounded) {
    all.push(css`
      border-radius: 15px;
    `);
  }
  return css(all);
};

const newStyles = ({ newStyles }: NewStylesProps) => (newStyles ? NewInputStyles : null);

const Input = styled("input")<StyledInputProps>`
  display: block;
  width: 100%;
  color: ${theme.colors.contentPrimary};
  background-color: #fff;
  background-image: none;
  border: 1.5px solid ${theme.colors.primaryBorderColor};
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  padding: ${({ inset }) => (inset ? "20px 0px 10px 10px" : "10px 16px")}; // Use ternary operator for padding
  font-size: 16px;
  font-weight: 300;
  line-height: 1.33;
  border-radius: 5px;
  margin: 1px;
  &::placeholder {
    color: ${theme.colors.contentSecondary};
  }
  &:focus {
    border-color: ${theme.colors.contentAccent};
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
  ${space}
  ${variants}
  ${newStyles}
`;

export type InputProps = React.ComponentProps<typeof Input>;

Input.defaultProps = {
  type: "text",
  autoCorrect: "off",
  autoCapitalize: "off",
  spellCheck: "false",
  newStyles: false,
  inset: false,
};

export default Input;
