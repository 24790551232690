import React, { useState, FC, SelectHTMLAttributes, FocusEvent, ChangeEvent } from "react";
import styled from "@emotion/styled";
import Select, { SelectProps } from "./Select";
import { theme } from "../nessie-web";

interface SelectLabelProps extends SelectHTMLAttributes<HTMLSelectElement>, SelectProps {
  label: string;
  id: string;
}

const Wrapper = styled.div`
  position: relative;
  select {
    margin-block: 6px;
  }
  select:focus + label {
    color: ${theme.colors.contentAccent};
  }
`;

const StyledLabel = styled.label<{ float: boolean }>`
  position: absolute;
  left: 0.5rem;
  top: 15px;
  color: ${theme.colors.contentSecondary};
  padding: 0 0.25rem;
  transition: all 0.2s ease;
  pointer-events: none;
  ${({ float }) =>
    float &&
    `
      top: 5px;
      font-size: 0.75rem;
    `}
`;

const SelectLabel: FC<SelectLabelProps> = ({ label, id, children, value: propValue, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState<string>(typeof propValue === "string" ? propValue : "");

  const handleFocus = (e: FocusEvent<HTMLSelectElement>) => {
    setIsFocused(true);
    props.onFocus && props.onFocus(e);
  };

  const handleBlur = (e: FocusEvent<HTMLSelectElement>) => {
    setIsFocused(false);
    props.onBlur && props.onBlur(e);
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
    props.onChange && props.onChange(e);
  };

  const float = isFocused || value.length > 0;

  return (
    <Wrapper>
      <Select
        {...props}
        id={id}
        inset={true}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      >
        {children}
      </Select>
      <StyledLabel htmlFor={id} float={float}>
        {label}
      </StyledLabel>
    </Wrapper>
  );
};

export default SelectLabel;
