import React, { useContext } from "react";
import { CheckmarkIcon, CloseIcon, ListItem, theme } from "../../nessie-web";
import { hasAlphabeticalCharacter, hasMinimumLength, hasSpecialOrNumericCharacter } from "./pwValidityHelpers";
import { TranslationContext } from "@src/components/translation/TranslationContext";

const {
  colors: { contentSuccess, dt_taro60, contentDanger },
} = theme;

type PasswordRequirementsProps = {
  password: string;
  highlightRequirements: boolean;
};

const PasswordRequirements = ({ password, highlightRequirements }: PasswordRequirementsProps) => {
  const minimumLengthState = hasMinimumLength(password, 8) ? "valid" : highlightRequirements ? "invalid" : "pending";
  const alphabeticState = hasAlphabeticalCharacter(password) ? "valid" : highlightRequirements ? "invalid" : "pending";
  const numericSpecialState = hasSpecialOrNumericCharacter(password)
    ? "valid"
    : highlightRequirements
    ? "invalid"
    : "pending";
  const t = useContext(TranslationContext);

  return (
    <div css={{ paddingLeft: 6, paddingBottom: 6 }}>
      <span
        css={{ fontWeight: "400", color: theme.colors.contentSecondary, fontSize: "15px" }}
        id="password-validation-message"
      >
        {t.translateWithFallback({
          str: "components.signup_teacher.password_requirements.password_must",
          fallback: "Password must:",
        })}
      </span>
      <PasswordRequirement type="minimum-length" state={minimumLengthState} />
      <PasswordRequirement type="includes-alphabetic" state={alphabeticState} />
      <PasswordRequirement type="includes-numeric-or-special" state={numericSpecialState} />
    </div>
  );
};

type PasswordRequirementProps = {
  state: "valid" | "pending" | "invalid";
  type: "minimum-length" | "includes-alphabetic" | "includes-numeric-or-special";
};

const PasswordRequirement = ({ state, type }: PasswordRequirementProps) => {
  const checkIcon = <CheckmarkIcon size="xs" color="contentSuccess" />;
  const xIcon = <CloseIcon size="xs" color="contentDanger" />;
  const emptyIcon = <CheckmarkIcon size="xs" color="taro60" />;
  const iconMap = {
    valid: checkIcon,
    pending: emptyIcon,
    invalid: xIcon,
  };
  const colorMap = {
    valid: contentSuccess,
    pending: dt_taro60,
    invalid: contentDanger,
  };
  const icon = iconMap[state];
  const t = useContext(TranslationContext);
  const validationMessages = {
    "minimum-length": t.translateWithFallback({
      str: "components.signup_teacher.password_requirements.min_length",
      fallback: "Be at least 8 characters",
    }),
    "includes-alphabetic": t.translateWithFallback({
      fallback: "Include 1 alphabetical character",
      str: "components.signup_teacher.password_requirements.alpha_char",
    }),
    "includes-numeric-or-special": t.translateWithFallback({
      fallback: "Include 1 number or special character",
      str: "components.signup_teacher.password_requirements.num_or_special_char",
    }),
    "not-same-as-email": t.translateWithFallback({
      fallback: "Not be the same as email",
      str: "components.signup_teacher.password_requirements.not_same_as_email",
    }),
  } as const;

  const color = colorMap[state];

  return (
    <ListItem leftAccessory={icon} title={<span css={{ color, fontSize: "15px" }}>{validationMessages[type]}</span>} />
  );
};

export default PasswordRequirements;
