import React, { createContext, ReactNode, useState } from "react";

type ActivationRedirectionContextType = {
  setDefaultRedirection: (() => void) | null;
  preventRedirection: (() => void) | null;
  setSuccessAction: ((callback: () => void) => void) | null;
  getRedirectionStatus: (() => boolean) | null;
  runSuccessAction: (() => void) | null;
};

const ActivationRedirectionContext = createContext<ActivationRedirectionContextType>({
  setDefaultRedirection: null,
  preventRedirection: null,
  setSuccessAction: null,
  getRedirectionStatus: null,
  runSuccessAction: null,
});

const ActivationRedirectionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [redirect, setRedirect] = useState(true);
  const [successAction, _setSuccessAction] = useState<(() => void) | null>(null);

  function setDefaultRedirection() {
    setRedirect(true);
    _setSuccessAction(null);
  }

  function preventRedirection() {
    setRedirect(false);
  }

  function setSuccessAction(callback: () => void) {
    _setSuccessAction(() => callback);
    setRedirect(false);
  }

  function getRedirectionStatus() {
    return redirect;
  }

  function runSuccessAction() {
    if (successAction !== null) successAction();
    setDefaultRedirection();
  }

  return (
    <ActivationRedirectionContext.Provider
      value={{
        setDefaultRedirection,
        preventRedirection,
        setSuccessAction,
        getRedirectionStatus,
        runSuccessAction,
      }}
    >
      {children}
    </ActivationRedirectionContext.Provider>
  );
};

export { ActivationRedirectionContext, ActivationRedirectionProvider };
